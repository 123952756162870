import React from "react"
import { graphql } from "gatsby"

import PhotosPageTemplate from "../../templates/pages/photos"

export const query = graphql`
  query photoQueryEn {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      surgeonName: surgeonName_en
      title: title_en
      description: description_en
      keywords: keywords_en
    },
    photoCategories: allSanityPhotoCategory {
      nodes {
        desc
        desc_en
        title
        title_en
        background {
          alt
          alt_en
          asset {
            gatsbyImageData
          }
        }
        link
        _id
      }
    }
  }  
`

const IndexPage = props => {
  const { data } = props

  return <PhotosPageTemplate isEn={true} data={data} />
};

export default IndexPage
